import React, { useState } from "react";
import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import { StudentCard, TeacherCard } from "~/components/content-card";
import UnorderedList from "~/components/List/Unordered";
import Button from "~/components/button";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
      <LessonLayout
        current_step={9}
        lesson={"Lesson 2"}
        color={"hs"}
        total_count={10}
        module_title="Separating Fact from Fiction"
      >
        <ContentWrapper>
          <H2 underline>Step 1</H2>

          <TeacherViewWrapper>
            <StudentCard
              fullScreen={studentFullScreen}
              hidden={teacherFullScreen}
              highSchool={true}
              setFullScreen={setStudentFullScreen}
            >
              <p>To conclude, return to the CBD websites you looked at in the first lesson.</p>

              <UnorderedList>
                <li>Reexamine each of the three websites.</li>
                <li>Use your rubric to score each website for accuracy of information.</li>
                <li>Be prepared to report how you scored the websites and to discuss the reasons for your scores.</li>
                <li>Consider the following questions:</li>

                <UnorderedList>
                  <li className="list-circle">Did your opinion change about the quality of each of the websites?</li>
                  <li className="list-circle">What are the strengths or weaknesses of each site with respect to the accuracy of information?</li>
                  <li className="list-circle">Do you think you will question the accuracy of information on websites differently in the future? How will that affect your use of the internet?</li>
                </UnorderedList>
              </UnorderedList>

              <div className="flex flex-col lg:flex-row">
                <Button
                  action="secondary"
                  className="my-2 lg:m-2"
                  color="indigo"
                  href="/article/cbd/cbd-now"
                  icon="external"
                  title="/article/safe-environment"
                >
                  CBD website 1
                </Button>
                <Button
                  action="secondary"
                  className="my-2 lg:m-2"
                  color="indigo"
                  href="/article/cbd/cbd-truth"
                  icon="external"
                  title="CBD website 2"
                >
                  CBD website 2
                </Button>
                <Button
                  action="secondary"
                  className="my-2 lg:m-2"
                  color="indigo"
                  href="/article/cbd/cansativa-organics"
                  icon="external"
                  title="CBD website 3"
                >
                  CBD website 3
                </Button>
              </div>
            </StudentCard>
            <TeacherCard
              fullScreen={teacherFullScreen}
              hidden={studentFullScreen}
              highSchool={true}
              setFullScreen={setTeacherFullScreen}
            >
              <p>In this step, students use their rubrics to reexamine the 3 CBD sites they evaluated in Lesson 1. (In Lesson 1, they evaluated web features. Now they will evaluate the accuracy of information on the sites using their newly expanded rubric.)</p>
              <p>Inform students whether to work individually or in teams.</p>
              <p>Students should consider the following questions as they evaluate the CBD sites for accuracy and be prepared for a class discussion.</p>

              <UnorderedList>
                <li>Did your opinion change about the quality of each of the websites?</li>
                <li>What are the strengths or weaknesses of each site with respect to the accuracy of information?</li>
                <li>Do you think you will question the accuracy of information on websites differently in the future? How will that affect your use of the internet?</li>
              </UnorderedList>

              <p>After students have a chance to complete this section of their rubrics, discuss any questions they may have about using their rubrics to score the accuracy of information.</p>
            </TeacherCard>
          </TeacherViewWrapper>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
